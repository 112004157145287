@use '../../../../../style.scss' as s;

.active-billing-view {
	display: grid;
	gap: s.space(md);

	grid-auto-flow: row;

	@include s.break-up(sm) {
		grid-auto-flow: row;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: minmax(250px, min-content) auto;
	}

	&__next-payment {
		grid-area: next-payment;
	}

	&__payment-method {
		grid-area: payment-method;
	}

	&__payment-history {
		grid-area: payment-history;
	}

}

.payment-method-view {
	&, &__content {
		display: flex;
		flex-direction: column;
	}

	&__content {
		flex: 1;
	}
	
	&__card {
		margin: s.space(0, auto, md, auto);
		flex: 1;
	}

	&__no-payment-method {
		margin: auto;
	}
}

.retry-payment-view {
	display: flex;
	flex-direction: column;
	
	&__info {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1;
	}
}

.next-payment-view {
	display: flex;
	flex-direction: column;

	&__info {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		// margin: auto 0;
		flex: 1;
		
		* {
			text-align: center !important;
		}
	}
}
@use '../../../../../style.scss' as s;

.invoice-history-view {
	margin-block-start: s.space(md);

	&__list {
		max-width: 40ch;
		margin: 0 auto;
		
		
		> li + li {
			margin-block-start: s.space(sm);
		}
	}
}

.invoice-view {
	@include s.elevate(sm);
	padding: s.space(sm);
	@include s.rounded(sm);
	background-color: s.color(highlight);

	&__reference-and-value, &__content {
		display: flex;
		justify-content: space-between;
	}

	&__content {
		flex-direction: column;
		row-gap: s.space(sm);

		@include s.break-up(sm) {
			flex-direction: row;
			row-gap: 0;
		}
	}

	&__details {
		display: grid;
		grid-template-columns: auto 1fr;
		column-gap: s.space(sm);
		> * {
			display: flex;
			align-items: center;
		}
	}

	&__download {
		margin-block-start: auto;
	}
}
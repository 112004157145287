@use '../../../style.scss' as s;

.keyValueTable {
	th {
		font-weight: bolder;
		text-align: start;
		padding-inline-end: s.space(sm);
	}

	td {
		.noData {
			color: gainsboro;
			font-size: .9rem;
			font-style: italic;
		}
	}
}

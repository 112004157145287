@use 'sass:map';
@use 'sass:list';
@use 'sass:math';


///////////////////////////////////////////////////////////////////////////////
// Space
///////////////////////////////////////////////////////////////////////////////
$spacings: (
	xs: .25rem,
	sm: .5rem,
	md: 1rem,
	lg: 2rem,
	xl: 4rem,
);

@function _get-space($key) {
	@if map.has-key($spacings, $key) {
		@return map.get($spacings, $key);
	}

	@return $key;
}

@function space($a, $b: null, $c: null, $d: null) {
	@if $a and $b and $c and $d {
		@return _get-space($a) _get-space($b) _get-space($c) _get-space($d);
	}
	@if $a and $b and $c {
		@return _get-space($a) _get-space($b) _get-space($c);
	}
	@if $a and $b {
		@return _get-space($a) _get-space($b);
	}
	@return _get-space($a);
}

$_pad-utils: list.append(map.keys($spacings), 0);
@each $pad in $_pad-utils {
	.pad-#{$pad} {
		padding: space($pad);
	}
}

$_margin-utils: list.append($_pad-utils, auto);
@each $margin in $_margin-utils {
	.mrg-#{$margin} {
		margin: space($margin);
	}

	.mrg-block-start-#{$margin} {
		margin-block-start: space($margin);
	}

	.mrg-inline-start-#{$margin} {
		margin-inline-start: space($margin);
	}
}

///////////////////////////////////////////////////////////////////////////////
// Time
///////////////////////////////////////////////////////////////////////////////
$durations: (
	xs: 100ms,
	sm: 200ms,
	md: 400ms,
	lg: 800ms,
	xl: 1600ms,
);

@function duration($key) {
	@if map.has-key($durations, $key) {
		@return map.get($durations, $key);
	}

	@return $key;
}

@mixin transition($duration, $props) {
	$duration-list: ();
	@for $_ from 0 through list.length($props) {
		$duration-list: list.append($duration-list, duration($duration));
	}

	transition: list.zip($props, $duration-list);
}


///////////////////////////////////////////////////////////////////////////////
// Light
///////////////////////////////////////////////////////////////////////////////
$shadow-depths: (
	xs: 1px,
	sm: 2px,
	md: 4px,
	lg: 8px,
	xl: 16px,
);
$_shadow-depth-keys: map.keys($shadow-depths);

$shadow-color: rgba(0, 0, 0, .2);

@function _box-shadow($inset, $offset, $scale-y: 1) {
	$shadow: ();
	@if $inset {
		$shadow: list.append($shadow, inset);
	}

	$shadow: list.join($shadow, (0 ($offset * $scale-y) $offset $shadow-color));

	@return $shadow;
}

@function shadow($inset, $depth, $scale-y: 1) {
	@if not map.has-key($shadow-depths, $depth) {
		@error 'Invalid shadow depth; expected one of: #{$_shadow-depth-keys}';
	}

	$shadows: ();

	$done: false;
	@each $depth-key in map.keys($shadow-depths) {
		@if not $done {
			$offset: map.get($shadow-depths, $depth-key);
			$shadow: _box-shadow($inset, $offset, $scale-y);
			$shadows: list.append($shadows, $shadow, $separator: comma);
		}

		@if $depth-key == $depth  {
			$done: true;
		}
	}

	@return $shadows;
}

@mixin flat() {
	box-shadow: none;
}

@mixin elevate($depth: md) {
	box-shadow: shadow(false, $depth);
}

@mixin inset($depth: md) {
	box-shadow: shadow(true, $depth);
}

@mixin emboss($depth: xs) {
	box-shadow: shadow(true, $depth, -1);
}


///////////////////////////////////////////////////////////////////////////////
// Color
///////////////////////////////////////////////////////////////////////////////
$dark: rgb(34, 34, 34);
$light: rgb(221, 221, 221);

$colors: (
	invisible: (
		main: none,
		contrast: inherit,
	),
	page: (
		main: $dark,
		contrast: $light,
	),
	panel: (
		main: rgb(51, 51, 51),
		contrast: $light,
	),
	highlight: (
		main: rgb(73, 73, 73),
		contrast: $light,
	),
	brand: (
		main: rgb(18, 162, 230),
		contrast: $light,
	),
	light: (
		main: $light,
		contrast: $dark,
	),
	good: (
		main: rgb(28, 205, 30),
		contrast: $dark,
	),
	warn: (
		main: rgb(207, 207, 73),
		contrast: $dark,
	),
	bad: (
		main: rgb(199, 41, 41),
		contrast: $light,
	),
);

:root {
	@each $colorName, $variants in $colors {
		@each $variantName, $color in $variants {
			--color-#{$colorName}--#{$variantName}: #{$color};
		}
	}

	--theme-bg: var(--color-page--main);
	--theme-fg: var(--color-page--contrast);
}

@mixin surface() {
	background-color: var(--theme-bg) !important;
	color: var(--theme-fg) !important;
}

.surface {
	@include surface();
}

@mixin theme($colorName) {
	--theme-bg: var(--color-#{$colorName}--main);
	--theme-fg: var(--color-#{$colorName}--contrast);
}

@each $colorName, $variants in $colors {
	.theme-#{colorName} {
		@include theme($colorName);
	}

	@each $variantName, $color in $variants {
		@if $variantName == main {
			.bg-#{$colorName} {
				background-color: var(--color-#{$colorName}--#{$variantName});
			}

			.fg-#{$colorName} {
				color: var(--color-#{$colorName}--#{$variantName});
			}
		}

		.bg-#{$colorName}--#{variantName} {
			background-color: var(--color-#{$colorName}--#{$variantName});
		}
	}
}

@function color($colorName, $colorVariant: main) {
	$col: map.get($colors, $colorName);
	@return map.get($col, $colorVariant);
}

@each $colorName, $variants in $colors {
	.theme-#{$colorName} {
		@include theme($colorName);
	}

	.fg-#{$colorName} {
		color: var(--color-#{$colorName}--main);
	}
}


///////////////////////////////////////////////////////////////////////////////
// Shape
///////////////////////////////////////////////////////////////////////////////
@mixin rounded($space: sm) {
	border-radius: space($space);
}

@mixin round() {
	border-radius: 50%;
}


///////////////////////////////////////////////////////////////////////////////
// Typography
///////////////////////////////////////////////////////////////////////////////
$font-sizes: (
	sm: .9rem,
	md: 1rem,
	lg: 1.3rem,
	xl: 1.6rem,
	xxl: 2rem,
);

@function get-font-size($size) {
	@if map.has-key($font-sizes, $size) {
		@return map.get($font-sizes, $size);
	}

	@error "Invalid font size #{$size}";
}

@mixin font-size($size) {
	font-size: get-font-size($size);
}

$font-families: (
	sans: (BearingSans, sans),
	display: (BearingDisplay, display),
	mono: (BearingMono, monospace),
);

@function get-font-family($family) {
	@if map.has-key($font-families, $family) {
		@return map.get($font-families, $family);
	}

	@return $family;
}

@mixin font-family($family) {
	font-family: get-font-family($family);
}


///////////////////////////////////////////////////////////////////////////////
// Layout
///////////////////////////////////////////////////////////////////////////////
@mixin stack($size: md) {

	> * {
		--stack-space: #{space($size)};
	}

	display: flex;
	flex-direction: column;

	> * + * {
		margin-block-start: var(--stack-space);
	}
}

.stack {
	@include stack();
}

@each $size in map.keys($spacings) {
	.stack--#{$size} {
		@include stack($size);
	}
}

@mixin flow($size: md) {
	display: flex;
	justify-content: flex-start;
	column-gap: space($size);
}

.flow {
	@include flow();
}
@each $size in map.keys($spacings) {
	.flow--#{$size} {
		@include flow($size);
	}
}

.flex-center {
	display: flex;
	justify-content: center;
}

.flex-between {
	display: flex;
	justify-content: space-between;
}

.flex-align-center {
	display: flex;
	align-items: center;
}

.flex-align-end {
	display: flex;
	align-items: flex-end;
}

.flex-1 {
	flex: 1;
}

.flex-wrap {
	flex-wrap: wrap;
}

///////////////////////////////////////////////////////////////////////////////
// Breakpoints
///////////////////////////////////////////////////////////////////////////////
$breakpoints: (
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
);

@mixin break-up($size) {
	@media (min-width: map.get($breakpoints, $size)) {
		@content;
	}
}


///////////////////////////////////////////////////////////////////////////////
// Accessibility
///////////////////////////////////////////////////////////////////////////////
@mixin visually-hidden() {
	clip-path: inset(100%);
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

.visually-hidden {
	@include visually-hidden();
}
@use '../../../style.scss' as s;

.copyable {
	display: flex;
	flex-direction: column;

	&__button {
		margin-block-start: s.space(sm);
		margin-inline-start: auto;
	}
}
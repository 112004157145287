@use '../../../../../../style.scss' as s;

.credit-card {
	display: flex;
	flex-direction: column;
	align-items: center;

	$width: 200px;
	width: $width;
	height: $width * 0.628;

	padding: s.space(sm);
	border: 1px solid s.color(light);
	border-radius: 10px;
	
	background-color: s.color(highlight);
	@include s.elevate();

	img {
		height: 72px;
		border-radius: 5px;
	}

	&__logo {
		margin: auto;
		margin-block-end: s.space(sm);
	}
}
@use '../style.scss' as s;

.app {
	display: grid;
	grid-template-areas:
		'main'
		'foot'
	;

	grid-template-rows: 1fr auto;
	grid-template-columns: 1fr;
	
	width: 100vw;
	min-height: 100vh;
	
	&__head {
		position: fixed;
		left: 0;
		top: 0;
	
		z-index: 1;

		height: 48px;
	}

	&__main {
		grid-area: main;

		margin-top: 48px;
		
		&, > * {
			width: 100%;
			height: 100%;
		}

		padding: s.space(lg, 0);
	}
	&__foot {
		grid-area: foot;
		padding: s.space(sm);
		z-index: 1;

		@include s.inset(sm);
	}

	&__head, &__foot {
		@include s.theme(panel);
		@include s.surface();
	}
}
.expander {
	position: relative;
	overflow: hidden;
	
	&__content {
		position: absolute;
		bottom: 0;
		width: 100%;

		visibility: hidden;

		&--visible {
			visibility: visible;
		}
	}
}
@use '../../../style.scss' as s;


@mixin control-focus {
	&:focus-visible {
		outline-color: s.color(brand);
		outline-offset: s.space(xs);
	}

	outline: 2px solid transparent;
	outline-offset: 0;

	@include s.transition(sm, (outline-color outline-offset));
}

@mixin control-base {
	font-size: inherit;
	font-family: inherit;

	border: none;
	@include s.rounded();

	min-height: 38px;

	@include s.theme(light);
	@include s.surface();
}

a, summary {
	@include control-focus();
	@include s.rounded(xs);
}

.input {
	@include control-base();
	@include control-focus();
	@include s.inset(xs);

	padding-inline: s.space(sm);

	&:focus {
		@include s.inset(xs);
	}
}

.button {
	@include control-base();
	@include control-focus();

	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;

	@include s.emboss(xs);

	min-width: 80px;
	padding-inline: 1rem;

	cursor: pointer;
	user-select: none;

	line-height: 1;

	&--invisible, &--icon {
		@include s.theme(invisible);
		@include s.flat();
	}

	&--icon {
		padding: 0;
		min-width: unset;
		width: 38px;
		height: 38px;
	}

	&--primary {
		@include s.theme(brand);
		font-weight: bold;
	}

	&--danger {
		@include s.theme(bad);
		font-weight: bold;
	}

	&__content {
		display: flex;
		align-items: center;

		text-align: center;

		svg {
			margin-inline-end: s.space(sm);
		}

		transform: translateY(var(--button-content-offset, 0));
	}

	&--enabled {
		&:active {
			@include s.inset(sm);
			--button-content-offset: 1px;
		}
	}

	&--disabled, &--action-in-progress {
		opacity: .5;
		@include s.flat();
		background-color: s.color(light, contrast);
		color: s.color(light);
	}

	&--disabled {
		cursor: not-allowed !important;
	}

	&--action-in-progress {
		cursor: wait !important;
	}

	&--full-width {
		width: 100%;
	}
}

.input {
	@include control-base();
	@include s.inset(xs);

	padding-inline: s.space(sm);

	&:focus {
		@include s.inset(xs);
	}
}
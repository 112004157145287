@use '../../../../style.scss' as s;

.landing {
	position: relative;

	z-index: 0;

	> * {
		z-index: 1;
	}

	&__background-image {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		background-image: url('./static/landing-background.png');
		background-size: cover;
		background-attachment: fixed;
	
		z-index: -1;
	}

	&__header {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__tagline {
		max-width: 525px;
	}

	&__feature-list {
		display: flex;
		flex-direction: column;

		> * + * {
			margin-top: s.space(lg);
		}

		@include s.break-up(sm) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: s.space(lg);

			> * + * {
				margin-top: 0;
			}
		}
	}

	&__feature-item {
		display: grid;
		grid-template-columns: auto 1fr;
		gap: s.space(md);
				
		font-weight: bold;
		
		> * {
			margin: auto 0;
		}
		
		svg {
			width: s.space(xl);
		}
	}

	&__cta {
		display: flex;
		flex-direction: column;
		gap: s.space(sm);

		@include s.break-up(sm) {
			flex-direction: row;
		}
	}
}
@use '../../../style.scss' as s;

.divider {
	--divider-size: 2px;
	--divider-margin: #{s.space(sm)};

	width: 100%;

	margin: var(--divider-margin) 0;

	border: none;
	border-bottom: var(--divider-size) solid s.color(light);
	opacity: .2;

	&--sm {
		--divider-size: 1px;
	}
}
@use '../../../style.scss' as s;

.nav-bar {
	$breakpoint: md;
	
	width: 100vw;
	> .content {
		height: 100%;
	}

	&__content {
		display: flex;
		justify-content: space-between;
		align-items: center;

		height: 100%;
	}

	@include s.elevate(lg);

	a {
		display: flex;
		align-items: center;
		text-decoration: none;

		font-weight: bold;
	}

	&__banner {
		display: flex;
		align-items: center;

		h1 {
			transform: translateY(-2px);
		}
		
		svg {
			width: 48px;
			margin-inline-end: s.space(sm);
		}
	}

	&__inline-menu {
		display: none;

		@include s.break-up($breakpoint) {
			display: flex;
			> * + * {
				margin-left: s.space(lg);
			}

			li {
				height: 100%;
			}
		}
	}

	&__hamburger-button, &__hamburger-menu {
		@include s.break-up($breakpoint) {
			display: none;
		}
	}

	&__hamburger-menu {
		display: flex;
		flex-direction: column;
		align-items: center;

		width: 100vw;

		@include s.elevate(lg);
		
		ul {
			display: flex;
			flex-direction: column;
			padding: s.space(sm);

			> * + * {
				margin-top: s.space(sm);
			}

			a {
				> * {
					width: 100%;
				}
				&:hover, &:focus {
					color: s.color(brand);
				}
			}
		}
	}
}
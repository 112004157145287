@use '../../../style' as s;

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	&__inner {		
		min-width: 0;
		width: calc(100% - s.space(lg));
		max-width: var(--content-inner-width);

		&--xs {
			--content-inner-width: 45ch;
		}

		&--sm {
			--content-inner-width: 60ch;
		}

		&--md {
			--content-inner-width: 80ch;
		}

		&--lg {
			--content-inner-width: 100ch;
		}
	}
}
@use '../../../style.scss' as s;

.typography {
	&--size {
		&-sm {
			@include s.font-size(sm);
		}
		&-md {
			@include s.font-size(md);
		}
		&-lg {
			@include s.font-size(lg);
		}
		&-xl {
			@include s.font-size(xl);
		}
		&-xxl {
			@include s.font-size(xxl);
		}
	}

	&--align {
		&-start {
			text-align: start;
		}
		&-center {
			text-align: center;
		}
		&-end {
			text-align: end;
		}
	}

	&--secondary {
		opacity: .8;
	}

	&--font {
		&-sans {
			font-family: BearingSans, sans-serif;
		}

		&-mono {
			font-family: BearingMono, monospace;
		}

		&-display {
			font-family: BearingDisplay, monospace;
			line-height: 1.2;
		}
	}

	&--over-image {
		text-shadow: 0 0 16px rgba(0,0,0,.2), 0 0 8px rgba(0,0,0,1), 0 0 4px rgba(0,0,0,.7);
		color: white;
	}

	&--weight {
		&-bold {
			font-weight: bold;
		}
	}
}
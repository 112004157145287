@use '../../../../style.scss' as s;

.documentation-page {
	&__nav {
		@include s.stack(sm);

		a {
			text-decoration: none;
		}
	}

	&__doc-link {
		@include s.font-size(lg);
		font-weight: bold;

		&--active {
			font-weight: bold;
			color: s.color(brand);
		}
	}

	&__section-link {
		$indent: 1ch;
		display: flex;
		* {
			padding-left: $indent;
			text-indent: -($indent);
		}
	}

	&__content {
		max-width: 100%;
		display: flex;
	}
}

.mobile-documentation-page {
	display: grid;
	grid-template-columns: 100vw;
	max-width: 100vw;
}
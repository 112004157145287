@use '../../../../../style.scss' as s;

.adminUserToolbar {
	row-gap: s.space(sm);
	.disabled {
		opacity: .5;
		filter: grayscale(100%);
		cursor: not-allowed;
	}
}

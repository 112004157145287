@use '../../../../style.scss' as s;


.admin-section {
	&__header {
		display: flex;
		align-items: center;
	}

	&__icon {
		margin-inline-end: s.space(sm);
	}
}
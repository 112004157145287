html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

@font-face {
  font-family: BearingDisplay;
  src: url("Michroma.64073b51.woff");
}

@font-face {
  font-family: BearingSans;
  src: url("FiraSans-Regular.f94e4a51.woff");
  font-weight: normal;
}

@font-face {
  font-family: BearingSans;
  src: url("FiraSans-Medium.37609f87.woff");
  font-weight: bold;
}

@font-face {
  font-family: BearingMono;
  src: url("FiraCode-Regular.0cd3f46e.woff2");
}

html {
  font-family: BearingSans, sans-serif;
}

body {
  font-size: 18px;
  line-height: 1.4;
}

.copy > * + *, .copy section > * + * {
  margin-top: .5rem;
}

.copy pre, .copy section pre {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
  border-radius: .5rem;
  padding: 1rem;
  font-family: BearingMono, monospace;
  font-size: 1rem;
  overflow: auto;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .2), inset 0 2px 2px rgba(0, 0, 0, .2);
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.copy code, .copy section code {
  background-color: #3b5b6d;
  border-radius: 5px;
  padding: 0 .5ch;
  font-family: BearingMono, monospace;
  font-weight: bolder;
}

.copy strong, .copy section strong {
  font-weight: bold;
}

.copy section section, .copy section section section {
  margin-left: 1rem;
}

.copy section section h2, .copy section section h3, .copy section section section h2, .copy section section section h3 {
  margin-left: -1rem;
}

.copy h1, .copy section h1 {
  margin-bottom: 32px;
}

.copy h2, .copy h3, .copy section h2, .copy section h3 {
  margin-top: 1rem;
  font-weight: bold;
}

.copy h2, .copy section h2 {
  border-bottom: 1px solid rgba(221, 221, 221, .3);
  font-size: 1.6rem;
}

.copy h3, .copy section h3 {
  font-size: 1.3rem;
}

.copy ul, .copy section ul {
  margin-left: 2rem;
  list-style-type: disc;
  list-style-position: outside;
}

.copy ol, .copy section ol {
  margin-left: 2rem;
  list-style-type: decimal;
  list-style-position: outside;
}

.copy ul ul, .copy ol ul, .copy section ul ul, .copy section ol ul {
  margin-left: 2rem;
  list-style-type: circle;
  list-style-position: outside;
}

.copy ol ol, .copy ul ol, .copy section ol ol, .copy section ul ol {
  margin-left: 2rem;
  list-style-type: lower-latin;
  list-style-position: outside;
}

.copy li, .copy section li {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.copy .website-url, .copy .website-name, .copy section .website-url, .copy section .website-name {
  font-weight: 600;
}

.copy .highlight, .copy section .highlight {
  color: #12a2e6;
}

.copy img, .copy section img {
  border-radius: .5rem;
  max-width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .2), 0 4px 4px rgba(0, 0, 0, .2);
}

.copy p + img, .copy section p + img, .copy img + p, .copy section img + p {
  margin-top: 2rem;
}

strong {
  font-weight: bold;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

a {
  color: inherit;
}

summary {
  cursor: pointer;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.tower-logo--default {
  fill: #ddd;
}

.tower-logo--brand {
  fill: #12a2e6;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.content {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.content__inner {
  max-width: var(--content-inner-width);
  width: calc(100% - 2rem);
  min-width: 0;
}

.content__inner--xs {
  --content-inner-width: 45ch;
}

.content__inner--sm {
  --content-inner-width: 60ch;
}

.content__inner--md {
  --content-inner-width: 80ch;
}

.content__inner--lg {
  --content-inner-width: 100ch;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

a, summary {
  outline-offset: 0;
  border-radius: .25rem;
  outline: 2px solid rgba(0, 0, 0, 0);
  transition: outline-color .2s, outline-offset .2s;
}

a:focus-visible {
  outline-offset: .25rem;
  outline-color: #12a2e6;
}

summary:focus-visible {
  outline-offset: .25rem;
  outline-color: #12a2e6;
}

.input {
  font-size: inherit;
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
  outline-offset: 0;
  border: none;
  border-radius: .5rem;
  outline: 2px solid rgba(0, 0, 0, 0);
  min-height: 38px;
  padding-left: .5rem;
  padding-right: .5rem;
  font-family: inherit;
  transition: outline-color .2s, outline-offset .2s;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .2);
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.input:focus-visible {
  outline-offset: .25rem;
  outline-color: #12a2e6;
}

.button {
  font-size: inherit;
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
  outline-offset: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none;
  border-radius: .5rem;
  outline: 2px solid rgba(0, 0, 0, 0);
  justify-content: center;
  align-items: center;
  min-width: 80px;
  min-height: 38px;
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: inherit;
  line-height: 1;
  text-decoration: none;
  transition: outline-color .2s, outline-offset .2s;
  display: flex;
  box-shadow: inset 0 -1px 1px rgba(0, 0, 0, .2);
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.button:focus-visible {
  outline-offset: .25rem;
  outline-color: #12a2e6;
}

.button--invisible, .button--icon {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
  box-shadow: none;
}

.button--icon {
  min-width: unset;
  width: 38px;
  height: 38px;
  padding: 0;
}

.button--primary {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
  font-weight: bold;
}

.button--danger {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
  font-weight: bold;
}

.button__content {
  text-align: center;
  transform: translateY(var(--button-content-offset, 0));
  align-items: center;
  display: flex;
}

.button__content svg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-right: .5rem;
}

.button__content svg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-right: .5rem;
}

.button__content svg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .5rem;
}

.button__content svg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .5rem;
}

.button--enabled:active {
  --button-content-offset: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .2), inset 0 2px 2px rgba(0, 0, 0, .2);
}

.button--disabled, .button--action-in-progress {
  opacity: .5;
  box-shadow: none;
  color: #ddd;
  background-color: #222;
}

.button--disabled {
  cursor: not-allowed !important;
}

.button--action-in-progress {
  cursor: wait !important;
}

.button--full-width {
  width: 100%;
}

.input {
  font-size: inherit;
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
  border: none;
  border-radius: .5rem;
  min-height: 38px;
  padding-left: .5rem;
  padding-right: .5rem;
  font-family: inherit;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .2);
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.input:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .2);
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.spinner {
  animation: 2s linear infinite svg-animation;
}

.spinner--sm {
  width: 1rem;
}

.spinner--md {
  width: 2rem;
}

.spinner--lg {
  width: 4rem;
}

.spinner--light circle {
  stroke: #ddd;
}

.spinner--dark circle {
  stroke: #222;
}

.spinner circle {
  fill: rgba(0, 0, 0, 0);
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 280px;
  stroke-width: 10px;
  transform-origin: 50%;
  animation: 2s ease-in-out infinite both circle-animation;
  display: block;
}

@keyframes svg-animation {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes circle-animation {
  0% {
    stroke-dashoffset: 280px;
    transform: rotate(0);
  }

  50% {
    stroke-dashoffset: 45px;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280px;
    transform: rotate(360deg);
  }
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.divider {
  --divider-size: 2px;
  --divider-margin: .5rem;
  margin: var(--divider-margin) 0;
  border: none;
  border-bottom: var(--divider-size) solid #ddd;
  opacity: .2;
  width: 100%;
}

.divider--sm {
  --divider-size: 1px;
}

.expander {
  position: relative;
  overflow: hidden;
}

.expander__content {
  visibility: hidden;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.expander__content--visible {
  visibility: visible;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.typography--size-sm {
  font-size: .9rem;
}

.typography--size-md {
  font-size: 1rem;
}

.typography--size-lg {
  font-size: 1.3rem;
}

.typography--size-xl {
  font-size: 1.6rem;
}

.typography--size-xxl {
  font-size: 2rem;
}

.typography--align-start:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  text-align: left;
}

.typography--align-start:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  text-align: left;
}

.typography--align-start:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.typography--align-start:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.typography--align-center {
  text-align: center;
}

.typography--align-end:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  text-align: right;
}

.typography--align-end:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  text-align: right;
}

.typography--align-end:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: left;
}

.typography--align-end:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: left;
}

.typography--secondary {
  opacity: .8;
}

.typography--font-sans {
  font-family: BearingSans, sans-serif;
}

.typography--font-mono {
  font-family: BearingMono, monospace;
}

.typography--font-display {
  font-family: BearingDisplay, monospace;
  line-height: 1.2;
}

.typography--over-image {
  text-shadow: 0 0 16px rgba(0, 0, 0, .2), 0 0 8px #000, 0 0 4px rgba(0, 0, 0, .7);
  color: #fff;
}

.typography--weight-bold {
  font-weight: bold;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.panel {
  --panel-opacity: .7;
  background-color: rgba(51, 51, 51, var(--panel-opacity));
  border-radius: .5rem;
  padding: 1rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .2), 0 4px 4px rgba(0, 0, 0, .2);
}

.panel--opaque {
  --panel-opacity: 1;
}

.panel--pad-sm {
  padding: .5rem;
}

.panel-title {
  align-items: center;
  margin-top: -.5rem;
  display: flex;
}

.panel-title svg {
  width: auto;
  height: 1.2em;
}

.panel-title svg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-right: .25em;
}

.panel-title svg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-right: .25em;
}

.panel-title svg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .25em;
}

.panel-title svg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .25em;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.popover {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
  border-radius: .5rem;
  padding: .5rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .2), 0 4px 4px rgba(0, 0, 0, .2), 0 8px 8px rgba(0, 0, 0, .2);
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.alert {
  color: var(--alert-fg);
  grid-template-columns: auto 1fr;
  grid-template-areas: "icon message"
                       "content content";
  column-gap: .5rem;
  display: grid;
}

@media (min-width: 576px) {
  .alert {
    grid-template-areas: "icon message"
                         ". content";
  }
}

.alert__icon {
  color: var(--alert-color);
  grid-area: icon;
}

.alert__message {
  color: var(--alert-color);
  grid-area: message;
  margin-top: auto;
  margin-bottom: auto;
}

.alert__content {
  grid-area: content;
}

.alert--filled {
  border-radius: .5rem;
  padding: .5rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.alert--error {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.alert--warning {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.alert--success {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.alert--info {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.alert--default .alert__icon {
  color: var(--theme-bg);
}

.alert--md {
  column-gap: .25rem;
  padding: .25rem;
}

.alert--lg {
  column-gap: .5rem;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.copyable {
  flex-direction: column;
  display: flex;
}

.copyable__button {
  margin-top: .5rem;
}

.copyable__button:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.copyable__button:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.copyable__button:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.copyable__button:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.form {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.form__fields {
  flex-direction: column;
  display: flex;
}

.form__fields > * {
  --stack-space: 1rem;
}

.form__fields > * + * {
  margin-top: var(--stack-space);
}

.form__actions {
  grid-template-columns: 1fr auto;
  display: grid;
}

.form__secondary-actions {
  align-items: center;
  display: flex;
}

.form__submit-button {
  margin-left: auto;
}

.form-field input {
  width: 100%;
  margin-top: .25rem;
}

.info-icon {
  pointer-events: all;
  background-color: rgba(0, 0, 0, 0);
  justify-content: center;
  align-items: center;
  height: 20px;
  display: flex;
}

.account-loading-spinner {
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.activeUsersList a {
  text-decoration: none !important;
}

.activeUsersList__name {
  text-decoration: underline;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.table {
  border-radius: .5rem;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .2), 0 4px 4px rgba(0, 0, 0, .2);
}

.table td, .table th {
  vertical-align: middle;
  padding: .5rem;
}

.table td:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-left: 1px solid rgba(34, 34, 34, .3);
}

.table td:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-left: 1px solid rgba(34, 34, 34, .3);
}

.table th:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-left: 1px solid rgba(34, 34, 34, .3);
}

.table th:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-left: 1px solid rgba(34, 34, 34, .3);
}

.table td:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-right: 1px solid rgba(34, 34, 34, .3);
}

.table td:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-right: 1px solid rgba(34, 34, 34, .3);
}

.table th:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-right: 1px solid rgba(34, 34, 34, .3);
}

.table th:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-right: 1px solid rgba(34, 34, 34, .3);
}

.table td:first-child:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-left: none;
}

.table td:first-child:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-left: none;
}

.table th:first-child:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-left: none;
}

.table th:first-child:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-left: none;
}

.table td:first-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-right: none;
}

.table td:first-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-right: none;
}

.table th:first-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-right: none;
}

.table th:first-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-right: none;
}

.table th:first-child:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-top-left-radius: .5rem;
}

.table th:first-child:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-top-left-radius: .5rem;
}

.table th:first-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: .5rem;
}

.table th:first-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: .5rem;
}

.table th:last-child:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-top-right-radius: .5rem;
}

.table th:last-child:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-top-right-radius: .5rem;
}

.table th:last-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: .5rem;
}

.table th:last-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: .5rem;
}

.table thead {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .2), 0 4px 4px rgba(0, 0, 0, .2);
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.table tbody tr:nth-child(2n) td {
  background-color: rgba(221, 221, 221, .2);
}

.table tbody tr:last-child:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.table tbody tr:last-child:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.table tbody tr:last-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.table tbody tr:last-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.table tbody tr:last-child td:first-child:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-bottom-left-radius: .5rem;
}

.table tbody tr:last-child td:first-child:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-bottom-left-radius: .5rem;
}

.table tbody tr:last-child td:first-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-bottom-right-radius: .5rem;
}

.table tbody tr:last-child td:first-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-bottom-right-radius: .5rem;
}

.table tbody tr:last-child td:last-child:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-bottom-right-radius: .5rem;
}

.table tbody tr:last-child td:last-child:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-bottom-right-radius: .5rem;
}

.table tbody tr:last-child td:last-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-bottom-left-radius: .5rem;
}

.table tbody tr:last-child td:last-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-bottom-left-radius: .5rem;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.active-users-view__billing-status {
  justify-content: space-between;
  display: flex;
}

.active-users-view__billing-status button {
  cursor: pointer;
  border: none;
  border-radius: .25rem;
  width: 24px;
  height: 24px;
  padding: 0;
}

.active-users-view__company-and-info {
  column-gap: .25rem;
  height: 100%;
  display: none;
}

@media (min-width: 768px) {
  .active-users-view__company-and-info {
    display: flex;
  }

  .active-users-view__company-name {
    display: none;
  }
}

.active-users-view__info-icon {
  margin: auto 0;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.admin-section__header {
  align-items: center;
  display: flex;
}

.admin-section__icon:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-right: .5rem;
}

.admin-section__icon:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-right: .5rem;
}

.admin-section__icon:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .5rem;
}

.admin-section__icon:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .5rem;
}

.admin-dev-tools .horizontal-form {
  display: flex;
}

.admin-dev-tools .horizontal-form > * + :not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.admin-dev-tools .horizontal-form > * + :not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.admin-dev-tools .horizontal-form > * + :-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.admin-dev-tools .horizontal-form > * + :is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.admin-dev-tools select {
  font-size: inherit;
}

.admin-dev-tools .horizontal {
  align-items: center;
  display: flex;
}

.admin-dev-tools .horizontal > * + :not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.admin-dev-tools .horizontal > * + :not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.admin-dev-tools .horizontal > * + :-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.admin-dev-tools .horizontal > * + :is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.admin-dev-tools section > * + * {
  margin-top: .5rem;
}

.admin-dev-tools section + section {
  border-top: 1px solid #474747;
  margin-top: .5rem;
  padding-top: .5rem;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.admin-page__content {
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  gap: 1rem;
  display: grid;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.keyValueTable th {
  font-weight: bolder;
}

.keyValueTable th:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  text-align: left;
  padding-right: .5rem;
}

.keyValueTable th:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  text-align: left;
  padding-right: .5rem;
}

.keyValueTable th:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
  padding-left: .5rem;
}

.keyValueTable th:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
  padding-left: .5rem;
}

.keyValueTable td .noData {
  color: #dcdcdc;
  font-size: .9rem;
  font-style: italic;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.invoice-history-view {
  margin-top: 1rem;
}

.invoice-history-view__list {
  max-width: 40ch;
  margin: 0 auto;
}

.invoice-history-view__list > li + li {
  margin-top: .5rem;
}

.invoice-view {
  background-color: #494949;
  border-radius: .5rem;
  padding: .5rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .2);
}

.invoice-view__reference-and-value, .invoice-view__content {
  justify-content: space-between;
  display: flex;
}

.invoice-view__content {
  flex-direction: column;
  row-gap: .5rem;
}

@media (min-width: 576px) {
  .invoice-view__content {
    flex-direction: row;
    row-gap: 0;
  }
}

.invoice-view__details {
  grid-template-columns: auto 1fr;
  column-gap: .5rem;
  display: grid;
}

.invoice-view__details > * {
  align-items: center;
  display: flex;
}

.invoice-view__download {
  margin-top: auto;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.adminUserToolbar {
  row-gap: .5rem;
}

.adminUserToolbar .disabled {
  opacity: .5;
  filter: grayscale();
  cursor: not-allowed;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.adminUserPage .details {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: .5rem 2rem;
  display: flex;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.nav-bar {
  width: 100vw;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .2), 0 4px 4px rgba(0, 0, 0, .2), 0 8px 8px rgba(0, 0, 0, .2);
}

.nav-bar > .content {
  height: 100%;
}

.nav-bar__content {
  justify-content: space-between;
  align-items: center;
  height: 100%;
  display: flex;
}

.nav-bar a {
  align-items: center;
  font-weight: bold;
  text-decoration: none;
  display: flex;
}

.nav-bar__banner {
  align-items: center;
  display: flex;
}

.nav-bar__banner h1 {
  transform: translateY(-2px);
}

.nav-bar__banner svg {
  width: 48px;
}

.nav-bar__banner svg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-right: .5rem;
}

.nav-bar__banner svg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-right: .5rem;
}

.nav-bar__banner svg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .5rem;
}

.nav-bar__banner svg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .5rem;
}

.nav-bar__inline-menu {
  display: none;
}

@media (min-width: 768px) {
  .nav-bar__inline-menu {
    display: flex;
  }

  .nav-bar__inline-menu > * + * {
    margin-left: 2rem;
  }

  .nav-bar__inline-menu li {
    height: 100%;
  }

  .nav-bar__hamburger-button, .nav-bar__hamburger-menu {
    display: none;
  }
}

.nav-bar__hamburger-menu {
  flex-direction: column;
  align-items: center;
  width: 100vw;
  display: flex;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .2), 0 4px 4px rgba(0, 0, 0, .2), 0 8px 8px rgba(0, 0, 0, .2);
}

.nav-bar__hamburger-menu ul {
  flex-direction: column;
  padding: .5rem;
  display: flex;
}

.nav-bar__hamburger-menu ul > * + * {
  margin-top: .5rem;
}

.nav-bar__hamburger-menu ul a > * {
  width: 100%;
}

.nav-bar__hamburger-menu ul a:hover, .nav-bar__hamburger-menu ul a:focus {
  color: #12a2e6;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.dashboard-template__header {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

.dashboard-template__back-link {
  align-items: center;
  display: flex;
}

.dashboard-template__username {
  margin-top: auto;
  padding-top: .5rem;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.dashboard-home .user-details {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.dashboard-home .user-details__stats, .dashboard-home .user-details__address {
  margin: auto;
}

.dashboard-home .user-details__stats {
  grid-template-columns: 1fr 1fr;
  row-gap: .25rem;
  display: grid;
}

.dashboard-home .user-details__stats-value {
  font-size: 2rem;
  font-weight: bold;
}

.dashboard-home .user-details__stats-value:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
  margin-right: .5rem;
}

.dashboard-home .user-details__stats-value:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
  margin-right: .5rem;
}

.dashboard-home .user-details__stats-value:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .5rem;
  margin-right: auto;
}

.dashboard-home .user-details__stats-value:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .5rem;
  margin-right: auto;
}

.dashboard-home .user-details__stats-label {
  margin-top: auto;
  margin-bottom: auto;
}

.dashboard-home .user-details__address > * {
  text-indent: -3ch;
  display: block;
}

.dashboard-home .user-details__address > :not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: 3ch;
}

.dashboard-home .user-details__address > :not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: 3ch;
}

.dashboard-home .user-details__address > :-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: 3ch;
}

.dashboard-home .user-details__address > :is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: 3ch;
}

.dashboard-home .dashboard-link-button {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
  --icon-color: #4490b4;
  padding: 1rem;
  transition: box-shadow .2s, transform .2s;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
}

.dashboard-home .dashboard-link-button.button--enabled:hover, .dashboard-home .dashboard-link-button.button--enabled:focus {
  --icon-color: #12a2e6;
  transform: translateY(-1px);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .2);
}

.dashboard-home .dashboard-link-button.button--disabled {
  --icon-color: inherit;
}

.dashboard-home .dashboard-link-button__layout {
  grid-template-columns: auto 1fr;
  grid-template-areas: "icon title"
                       "icon description";
  row-gap: .25rem;
  width: 100%;
  display: grid;
}

.dashboard-home .dashboard-link-button__icon {
  color: var(--icon-color);
  grid-area: icon;
  transition: color .1s;
}

.dashboard-home .dashboard-link-button__title {
  grid-area: title;
  margin-top: auto;
}

.dashboard-home .dashboard-link-button__description {
  grid-area: description;
  margin-bottom: auto;
}

.dashboard-home__links {
  grid-template-columns: 1fr;
  gap: .5rem;
  display: grid;
}

@media (min-width: 576px) {
  .dashboard-home__links {
    grid-template-columns: 1fr 1fr;
  }
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.landing {
  z-index: 0;
  position: relative;
}

.landing > * {
  z-index: 1;
}

.landing__background-image {
  z-index: -1;
  background-image: url("landing-background.237cdee8.png");
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.landing__header {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.landing__tagline {
  max-width: 525px;
}

.landing__feature-list {
  flex-direction: column;
  display: flex;
}

.landing__feature-list > * + * {
  margin-top: 2rem;
}

@media (min-width: 576px) {
  .landing__feature-list {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    display: grid;
  }

  .landing__feature-list > * + * {
    margin-top: 0;
  }
}

.landing__feature-item {
  grid-template-columns: auto 1fr;
  gap: 1rem;
  font-weight: bold;
  display: grid;
}

.landing__feature-item > * {
  margin: auto 0;
}

.landing__feature-item svg {
  width: 4rem;
}

.landing__cta {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

@media (min-width: 576px) {
  .landing__cta {
    flex-direction: row;
  }
}

.checkout-complete-page {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.error-page {
  justify-content: center;
  align-items: center;
  display: flex;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.credit-card {
  background-color: #494949;
  border: 1px solid #ddd;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 125.6px;
  padding: .5rem;
  display: flex;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .2), 0 4px 4px rgba(0, 0, 0, .2);
}

.credit-card img {
  border-radius: 5px;
  height: 72px;
}

.credit-card__logo {
  margin: auto;
  margin-bottom: .5rem;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.active-billing-view {
  grid-auto-flow: row;
  gap: 1rem;
  display: grid;
}

@media (min-width: 576px) {
  .active-billing-view {
    grid-template-rows: minmax(250px, min-content) auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
  }
}

.active-billing-view__next-payment {
  grid-area: next-payment;
}

.active-billing-view__payment-method {
  grid-area: payment-method;
}

.active-billing-view__payment-history {
  grid-area: payment-history;
}

.payment-method-view, .payment-method-view__content {
  flex-direction: column;
  display: flex;
}

.payment-method-view__content {
  flex: 1;
}

.payment-method-view__card {
  flex: 1;
  margin: 0 auto 1rem;
}

.payment-method-view__no-payment-method {
  margin: auto;
}

.retry-payment-view {
  flex-direction: column;
  display: flex;
}

.retry-payment-view__info {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.next-payment-view {
  flex-direction: column;
  display: flex;
}

.next-payment-view__info {
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
  display: flex;
}

.next-payment-view__info * {
  text-align: center !important;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.sidebar-template {
  grid-template-columns: 230px calc(100vw - 230px);
  grid-template-areas: "sidebar main";
  display: grid;
}

.sidebar-template__sidebar {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
  grid-area: sidebar;
  width: 230px;
  height: calc(100vh - 48px);
  padding: 1rem;
  position: fixed;
  top: 48px;
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.sidebar-template__main {
  grid-area: main;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.documentation-page__nav {
  flex-direction: column;
  display: flex;
}

.documentation-page__nav > * {
  --stack-space: .5rem;
}

.documentation-page__nav > * + * {
  margin-top: var(--stack-space);
}

.documentation-page__nav a {
  text-decoration: none;
}

.documentation-page__doc-link {
  font-size: 1.3rem;
  font-weight: bold;
}

.documentation-page__doc-link--active {
  color: #12a2e6;
  font-weight: bold;
}

.documentation-page__section-link {
  display: flex;
}

.documentation-page__section-link * {
  text-indent: -1ch;
  padding-left: 1ch;
}

.documentation-page__content {
  max-width: 100%;
  display: flex;
}

.mobile-documentation-page {
  grid-template-columns: 100vw;
  max-width: 100vw;
  display: grid;
}

.pad-xs {
  padding: .25rem;
}

.pad-sm {
  padding: .5rem;
}

.pad-md {
  padding: 1rem;
}

.pad-lg {
  padding: 2rem;
}

.pad-xl {
  padding: 4rem;
}

.pad-0 {
  padding: 0;
}

.mrg-xs {
  margin: .25rem;
}

.mrg-block-start-xs {
  margin-top: .25rem;
}

.mrg-inline-start-xs:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .25rem;
}

.mrg-inline-start-xs:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-inline-start-xs:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .25rem;
}

.mrg-sm {
  margin: .5rem;
}

.mrg-block-start-sm {
  margin-top: .5rem;
}

.mrg-inline-start-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.mrg-inline-start-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-inline-start-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mrg-md {
  margin: 1rem;
}

.mrg-block-start-md {
  margin-top: 1rem;
}

.mrg-inline-start-md:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.mrg-inline-start-md:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-inline-start-md:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.mrg-lg {
  margin: 2rem;
}

.mrg-block-start-lg {
  margin-top: 2rem;
}

.mrg-inline-start-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 2rem;
}

.mrg-inline-start-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-inline-start-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 2rem;
}

.mrg-xl {
  margin: 4rem;
}

.mrg-block-start-xl {
  margin-top: 4rem;
}

.mrg-inline-start-xl:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 4rem;
}

.mrg-inline-start-xl:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-inline-start-xl:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 4rem;
}

.mrg-0 {
  margin: 0;
}

.mrg-block-start-0 {
  margin-top: 0;
}

.mrg-inline-start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.mrg-inline-start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-inline-start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.mrg-auto {
  margin: auto;
}

.mrg-block-start-auto {
  margin-top: auto;
}

.mrg-inline-start-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.mrg-inline-start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mrg-inline-start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

:root {
  --color-invisible--main: none;
  --color-invisible--contrast: inherit;
  --color-page--main: #222;
  --color-page--contrast: #ddd;
  --color-panel--main: #333;
  --color-panel--contrast: #ddd;
  --color-highlight--main: #494949;
  --color-highlight--contrast: #ddd;
  --color-brand--main: #12a2e6;
  --color-brand--contrast: #ddd;
  --color-light--main: #ddd;
  --color-light--contrast: #222;
  --color-good--main: #1ccd1e;
  --color-good--contrast: #222;
  --color-warn--main: #cfcf49;
  --color-warn--contrast: #222;
  --color-bad--main: #c72929;
  --color-bad--contrast: #ddd;
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.surface {
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

.bg-invisible {
  background-color: var(--color-invisible--main);
}

.bg-invisible--variantName {
  background-color: var(--color-invisible--main);
  background-color: var(--color-invisible--contrast);
}

.bg-page {
  background-color: var(--color-page--main);
}

.bg-page--variantName {
  background-color: var(--color-page--main);
  background-color: var(--color-page--contrast);
}

.bg-panel {
  background-color: var(--color-panel--main);
}

.bg-panel--variantName {
  background-color: var(--color-panel--main);
  background-color: var(--color-panel--contrast);
}

.bg-highlight {
  background-color: var(--color-highlight--main);
}

.bg-highlight--variantName {
  background-color: var(--color-highlight--main);
  background-color: var(--color-highlight--contrast);
}

.bg-brand {
  background-color: var(--color-brand--main);
}

.bg-brand--variantName {
  background-color: var(--color-brand--main);
  background-color: var(--color-brand--contrast);
}

.bg-light {
  background-color: var(--color-light--main);
}

.bg-light--variantName {
  background-color: var(--color-light--main);
  background-color: var(--color-light--contrast);
}

.bg-good {
  background-color: var(--color-good--main);
}

.bg-good--variantName {
  background-color: var(--color-good--main);
  background-color: var(--color-good--contrast);
}

.bg-warn {
  background-color: var(--color-warn--main);
}

.bg-warn--variantName {
  background-color: var(--color-warn--main);
  background-color: var(--color-warn--contrast);
}

.theme-colorName {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.bg-bad {
  background-color: var(--color-bad--main);
}

.bg-bad--variantName {
  background-color: var(--color-bad--main);
  background-color: var(--color-bad--contrast);
}

.theme-invisible {
  --theme-bg: var(--color-invisible--main);
  --theme-fg: var(--color-invisible--contrast);
}

.fg-invisible {
  color: var(--color-invisible--main);
}

.theme-page {
  --theme-bg: var(--color-page--main);
  --theme-fg: var(--color-page--contrast);
}

.fg-page {
  color: var(--color-page--main);
}

.theme-panel {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
}

.fg-panel {
  color: var(--color-panel--main);
}

.theme-highlight {
  --theme-bg: var(--color-highlight--main);
  --theme-fg: var(--color-highlight--contrast);
}

.fg-highlight {
  color: var(--color-highlight--main);
}

.theme-brand {
  --theme-bg: var(--color-brand--main);
  --theme-fg: var(--color-brand--contrast);
}

.fg-brand {
  color: var(--color-brand--main);
}

.theme-light {
  --theme-bg: var(--color-light--main);
  --theme-fg: var(--color-light--contrast);
}

.fg-light {
  color: var(--color-light--main);
}

.theme-good {
  --theme-bg: var(--color-good--main);
  --theme-fg: var(--color-good--contrast);
}

.fg-good {
  color: var(--color-good--main);
}

.theme-warn {
  --theme-bg: var(--color-warn--main);
  --theme-fg: var(--color-warn--contrast);
}

.fg-warn {
  color: var(--color-warn--main);
}

.theme-bad {
  --theme-bg: var(--color-bad--main);
  --theme-fg: var(--color-bad--contrast);
}

.fg-bad {
  color: var(--color-bad--main);
}

.stack {
  flex-direction: column;
  display: flex;
}

.stack > * {
  --stack-space: 1rem;
}

.stack > * + * {
  margin-top: var(--stack-space);
}

.stack--xs {
  flex-direction: column;
  display: flex;
}

.stack--xs > * {
  --stack-space: .25rem;
}

.stack--xs > * + * {
  margin-top: var(--stack-space);
}

.stack--sm {
  flex-direction: column;
  display: flex;
}

.stack--sm > * {
  --stack-space: .5rem;
}

.stack--sm > * + * {
  margin-top: var(--stack-space);
}

.stack--md {
  flex-direction: column;
  display: flex;
}

.stack--md > * {
  --stack-space: 1rem;
}

.stack--md > * + * {
  margin-top: var(--stack-space);
}

.stack--lg {
  flex-direction: column;
  display: flex;
}

.stack--lg > * {
  --stack-space: 2rem;
}

.stack--lg > * + * {
  margin-top: var(--stack-space);
}

.stack--xl {
  flex-direction: column;
  display: flex;
}

.stack--xl > * {
  --stack-space: 4rem;
}

.stack--xl > * + * {
  margin-top: var(--stack-space);
}

.flow {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--xs {
  justify-content: flex-start;
  column-gap: .25rem;
  display: flex;
}

.flow--sm {
  justify-content: flex-start;
  column-gap: .5rem;
  display: flex;
}

.flow--md {
  justify-content: flex-start;
  column-gap: 1rem;
  display: flex;
}

.flow--lg {
  justify-content: flex-start;
  column-gap: 2rem;
  display: flex;
}

.flow--xl {
  justify-content: flex-start;
  column-gap: 4rem;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.flex-align-center {
  align-items: center;
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.app {
  grid-template: "main" 1fr
                 "foot"
                 / 1fr;
  width: 100vw;
  min-height: 100vh;
  display: grid;
}

.app__head {
  z-index: 1;
  height: 48px;
  position: fixed;
  top: 0;
  left: 0;
}

.app__main {
  grid-area: main;
  margin-top: 48px;
  padding: 2rem 0;
}

.app__main, .app__main > * {
  width: 100%;
  height: 100%;
}

.app__foot {
  z-index: 1;
  grid-area: foot;
  padding: .5rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .2), inset 0 2px 2px rgba(0, 0, 0, .2);
}

.app__head, .app__foot {
  --theme-bg: var(--color-panel--main);
  --theme-fg: var(--color-panel--contrast);
  background-color: var(--theme-bg) !important;
  color: var(--theme-fg) !important;
}

/*# sourceMappingURL=index.0a73a4ca.css.map */

@use '../../../style.scss' as s;


.sidebar-template {
	$sidebar-width: 230px;
	
	display: grid;
	grid-template-columns: $sidebar-width calc(100vw - $sidebar-width);
	grid-template-areas: 'sidebar main';
	
	&__sidebar {
		position: fixed;
		width: $sidebar-width;
		top: 48px;
		height: calc(100vh - 48px);
		
		grid-area: sidebar;
		@include s.surface();
		@include s.theme(panel);
		padding: s.space(md);
	}

	&__main {
		grid-area: main;
	}
}
@use '../../../style.scss' as s;

.panel {
	--panel-opacity: .7;

	background-color: rgba(s.color(panel), var(--panel-opacity));

	padding: s.space(md);
	@include s.rounded();
	@include s.elevate();

	&--opaque {
		--panel-opacity: 1;
	}

	&--pad-sm {
		padding: s.space(sm);
	}
}

.panel-title {
	display: flex;
	align-items: center;

	margin-top: -(s.space(sm));

	svg {
		height: 1.2em;
		width: auto;
		margin-inline-end: .25em;
	}
}
.admin-dev-tools {
	.horizontal-form {
		display: flex;
		> * + * {
			margin-inline-start: .5rem;
		}
	}

	select {
		font-size: inherit;
	}
	
	.horizontal {
		display: flex;
		align-items: center;

		> * + * {
			margin-inline-start: .5rem;
		}
	}
	
	section {
		> * + * {
			margin-top: .5rem;
		}
		
		
		+ section {
			margin-block-start: .5rem;
			padding-block-start: .5rem;
			border-block-start: 1px solid #474747;
		}
	}
}
@use 'sass:color';
@use '../../../../style.scss' as s;


.dashboard-home {
	.user-details {
		display: grid;
		grid-template-columns: 1fr 1fr;
	
		&__stats, &__address {
			margin: auto;
		}
	
		&__stats {
			display: grid;
			grid-template-columns: 1fr 1fr;
			row-gap: s.space(xs);
			&-value {
				margin-inline-start: auto;
				margin-inline-end: s.space(sm);
				font-size: 2rem;
				font-weight: bold;
			}
	
			&-label {
				margin-block: auto;
			}
		}
	
		&__address {
			> * {
				display: block;
				text-indent: -3ch;
				padding-inline-start: 3ch;
			}
		}
	}

	.dashboard-link-button {
		padding: s.space(md);
		@include s.theme(panel);
		@include s.elevate(xs);

		--icon-color: #{color.adjust(s.color(brand), $saturation: -40%)};
		@include s.transition(sm, (box-shadow, transform));

		&.button--enabled {
			&:hover, &:focus {
				@include s.elevate(sm);
	
				--icon-color: #{s.color(brand)};
				transform: translateY(-1px);
			}
		}

		&.button--disabled {
			--icon-color: inherit;
		}
		
		&__layout {
			display: grid;
			grid-template-columns: auto 1fr;
			grid-template-areas: 
				'icon title'
				'icon description'
			;
			row-gap: s.space(xs);
			width: 100%;
		}
	
		&__icon {
			grid-area: icon;

			color: var(--icon-color);
			transition: color s.duration(xs);
		}
		&__title {
			grid-area: title;
			margin-block-start: auto;
		}
		&__description {
			grid-area: description;
			margin-block-end: auto;
		}
	}

	&__links {
		display: grid;
		grid-template-columns: 1fr;
		gap: s.space(sm);
	
		@include s.break-up(sm) {
			grid-template-columns: 1fr 1fr;
		}
	}
}
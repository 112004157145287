@use '../style.scss' as s;


@font-face {
	font-family: BearingDisplay;
	src: url('./fonts/Michroma.woff');
}

@font-face {
	font-family: BearingSans;
	src: url('./fonts/FiraSans-Regular.woff');
	font-weight: normal;
}

@font-face {
	font-family: BearingSans;
	src: url('./fonts/FiraSans-Medium.woff');
	font-weight: bold;
}

@font-face {
	font-family: BearingMono;
	src: url('./fonts/FiraCode-Regular.woff2');
}

html {
	font-family: BearingSans, sans-serif;
}

body {
	line-height: 1.4;
	font-size: 18px;
}

.copy, .copy section {
	> * + * {
		margin-block-start: .5rem;
	}

	pre {
		font-family: BearingMono, monospace;
		padding: s.space(md);

		@include s.font-size(md);

		@include s.surface();
		@include s.theme(light);
		@include s.inset(sm);
		@include s.rounded();

		overflow: auto;
	}

	code {
		@include s.font-family(mono);
		padding: 0 .5ch;
		border-radius: 5px;
		background-color: #3B5B6D;
		font-weight: bolder;
	}

	strong {
		font-weight: bold;
	}

	section {
		section {
			margin-left: s.space(md);

			h2, h3 {
				margin-left: -(s.space(md));
			}
		}
	}

	h1 {
		margin-bottom: 32px;
	}

	h2, h3 {
		font-weight: bold;
		margin-top: 1rem;
	}

	h2 {
		@include s.font-size(xl);
		border-bottom: 1px solid rgba(s.color(light), .3);
	}

	h3 {
		@include s.font-size(lg);
	}

	@mixin list() {
		list-style-position: outside; 
		margin-left: s.space(lg);
	}

	ul { 
		@include list();
		list-style-type: disc; 
	}

	ol {
		@include list();
		list-style-type: decimal; 
	}

	ul ul, ol ul { 
		@include list();
		list-style-type: circle; 
	}

	ol ol, ul ol {
		@include list();
		list-style-type: lower-latin; 
	}

	li {
		margin-top: s.space(sm);
		margin-bottom: s.space(sm);
	}

	.website-url, .website-name {
		font-weight: 600;
	}

	.highlight {
		color: s.color(brand);
	}

	img {
		max-width: 100%;
		@include s.elevate(md);
		@include s.rounded();
	}

	p + img {
		margin-top: s.space(lg);
	}

	img + p {
		margin-top: s.space(lg);
	}
}

strong {
	font-weight: bold;
}
@use '../../../style.scss' as s;

.spinner {
	animation: 2s linear infinite svg-animation;


	&--sm {
		width: s.space(md);
	}
	&--md {
		width: s.space(lg);
	}
	&--lg {
		width: s.space(xl);
	}

	&--light {
		circle {
			stroke: s.color(page, contrast);
		}
	}

	&--dark {
		circle {
			stroke: s.color(light, contrast);
		}
	}

	circle {
		animation: 2s ease-in-out infinite both circle-animation;
		display: block;
		fill: transparent;
		stroke-linecap: round;
		stroke-dasharray: 283;
		stroke-dashoffset: 280;
		stroke-width: 10px;
		transform-origin: 50% 50%;
	}
}

@keyframes svg-animation {
	0% {
		transform: rotateZ(0deg);
	}
	100% {
		transform: rotateZ(360deg)
	}
}

@keyframes circle-animation {
	0% {
		stroke-dashoffset: 280;
		transform: rotate(0);
	}
	
	50% {
		stroke-dashoffset: 45;
		transform: rotate(45deg);
	}
	
	100% {
		stroke-dashoffset: 280;
		transform: rotate(360deg);
	}
}
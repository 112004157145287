@use '../../../style.scss' as s;


.dashboard-template {
	&__header {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	&__back-link {
		display: flex;
		align-items: center;
	}

	&__username {
		margin-block-start: auto;
		padding-block-start: s.space(sm);
	}
}
@use '../../../style.scss' as s;


.alert {
	display: grid;
	grid-template-areas: 
		'icon    message'
		'content content'
	;

	@include s.break-up(sm) {
		grid-template-areas: 
		'icon    message'
		'.       content'
	;
	}
	
	grid-template-columns: auto 1fr;
	column-gap: s.space(sm);
	
	// background-color: var(--alert-bg);
	color: var(--alert-fg);
	
	&__icon {
		grid-area: icon;
		color: var(--alert-color);
	}

	&__message {
		grid-area: message;
		color: var(--alert-color);
		margin-block: auto;
	}

	&__content {
		grid-area: content;
	}
	
	&--filled {
		@include s.rounded();
		padding: s.space(sm);
		@include s.surface();
		
		@include s.elevate(xs);
	}

	&--error {
		@include s.theme(bad);
	}
	&--warning {
		@include s.theme(warn);
	}
	&--success {
		@include s.theme(good);
	}
	&--info {
		@include s.theme(brand);
	}

	&--default {
		.alert__icon {
			color: var(--theme-bg);
		}
	}


	&--md {
		column-gap: s.space(xs);
		padding: s.space(xs);
	}
	&--lg {
		column-gap: s.space(sm);
	}
}
@use '../../../style.scss' as s;

.table {
	$border-radius: s.space(sm);
	
	width: 100%;
	overflow: hidden;
	
	border-radius: $border-radius;


	@include s.elevate();
	
	td, th {
		padding: s.space(sm);
		vertical-align: middle;

		border-inline-start: 1px solid rgba(s.color(light, contrast), .3);
		&:first-child {
			border-inline-start: none;
		}
	}

	
	th {
		&:first-child {
			border-start-start-radius: $border-radius;
		}
		
		&:last-child {
			border-start-end-radius: $border-radius;
		}
	}
	
	thead {
		@include s.surface();
		@include s.theme(light);
	}

	thead {
		@include s.elevate();
	}

	tbody {
		tr {
			&:nth-child(even) {
				td {
					background-color: rgba(s.color(light), .2);
				}
			}
			
			&:last-child {
				border-end-start-radius: $border-radius;
				border-end-end-radius: $border-radius;

				td:first-child {
					border-end-start-radius: $border-radius;
				}

				td:last-child {
					border-end-end-radius: $border-radius;
				}
			}
		}
	}
}
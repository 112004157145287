@use '../../../style.scss' as s;


.form {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	
	&__fields {
		@include s.stack(md);
	}

	&__actions {
		display: grid;
		grid-template-columns: 1fr auto;
	}

	&__secondary-actions {
		display: flex;
		align-items: center;
	}
	
	&__submit-button {
		margin-left: auto;
	}
}

.form-field {
	input {
		width: 100%;
		margin-block-start: s.space(xs);
	}
}
@use '../../../../../style.scss' as s;

.active-users-view {
	&__billing-status {
		display: flex;
		justify-content: space-between;

		button {
			padding: 0;
			border: none;
			height: 24px;
			width: 24px;
			cursor: pointer;

			@include s.rounded(xs);
		}
	}

	&__company-and-info {
		display: none;

		@include s.break-up(md) {
			display: flex;
		}
		height: 100%;
		column-gap: s.space(xs);
	}

	&__company-name {
		@include s.break-up(md) {
			display: none;
		}
	}

	&__info-icon {
		margin: auto 0;
	}
}
@use '../../../style.scss' as s;


.tower-logo {
	&--default {
		fill: s.color(light);
	}

	&--brand {
		fill: s.color(brand);
	}
}
@use '../../../../style' as s;

.adminUserPage {
	.details {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		gap: s.space(sm, lg);
	}
}